.bookstore {
  /* padding: 3em; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 40px;
  background-color: rgb(242, 242, 211);
}

.btn {
  font-size: 16px;
  font-family: Georgia, "Times New Roman", Times, serif;
  /* background-color: rgb(23, 146, 195); */
  background-color: #001f3f;
  color: aliceblue;
  cursor: pointer;
}
