.search-book-container {
    width: 80%;
    margin: 0 auto;
  }
  
  .search-book-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      gap: 20px;
      padding-top: 30px;
    }
    
    .search-book-card {
      position: relative;
      cursor:pointer;
      border-radius: 8px;
      height: 350px;
      width: 200px;
      overflow: hidden;
      padding-bottom: 60px;
    }
    .search-book-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .img-container {
      height: 80%;
      overflow: hidden;
    }
    
    .img-container img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .search-card-info-container {
      padding: 0px;
      text-align: center;
    }
    
    .book-name {
      font-size: 14px;
      text-align: center;
    }
  
    .total-page, .price{
      font-size: 16px;
      color: #888;
      text-align: center;
      margin-top: 5px;
    }
    .search-book-container .filter {
      display: none;
    }