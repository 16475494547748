.container {
  width: 80vw;
  margin: auto;
  align-items: center;
}

.book-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  padding-top: 30px;
}

.book-card {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  height: 350px;
  width: 200px;
  overflow: hidden;
  padding-bottom: 60px;
}
.book-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #eef7ce;
}

.img-container {
  height: 80%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-info-container {
  padding: 0px;
  text-align: center;
}

.book-name {
  font-size: 14px;
  text-align: center;
}

.total-page,
.price {
  font-size: 16px;
  color: #888;
  text-align: center;
  margin-top: 5px;
}
