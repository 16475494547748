.footer-container {
  display: flex;
  flex-direction: column;
  background-color: #312d2d;
  color: rgb(255, 255, 255);
  padding: 50px;
  margin: 40px;
  max-width: 100vw;
  /* width: 100vw; */
  padding: 20px;
  padding-bottom: 0px;
  margin: 0;
}

.footer-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 50px;
}

/* .footer-container :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  } */
.links {
  display: flex;
  list-style: none;
}
.list-item {
  font-size: 18px;
  margin-right: 20px; /* Adjust the margin to set space between items */
}
.social-media-links {
  display: flex;
  justify-content: center;
  margin-left: -80px;
  /* margin-right: 200px; */
  gap: 100px;
}
.icon-container {
  display: flex;
  /* align-items: center; */
}
.icon-text {
  margin-left: -40px;
}
.icon {
  width: 20px;
  height: 20px;
}
.copyright {
  padding-bottom: 0px;
  align-self: center;
}
