@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Raleway:wght@100;200&display=swap");

.navbar {
  display: flex;
  /* margin: auto; */
  width: 100%;
  padding: 20px 0;
  margin-bottom: 20px;
  justify-content: flex-start;
  background-color: #b7e1f3;
}
.static {
  display: flex;
  margin-right: 15vw;
}
.title {
  /* width: 50%; */
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  margin-right: 50px;
  padding: 0px;
  font-size: 3rem;
  letter-spacing: 0.9px;
  background: linear-gradient(
    90deg,
    rgb(100, 50, 10) 0%,
    rgb(239, 195, 160) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* width: fit-content; */
}

.logo {
  flex: none;
  width: 70px;
  height: 60px;
  margin-left: 50px;
  margin-right: 0;
  padding-right: 0px;
}
/* .title {
    flex: 1;
    flex-shrink: 10px;
    margin-left: 50px;
    margin-right: 40px;
    padding-right: 0px;
    width: 200px;
} */
.navbar-links {
  display: flex;
  gap: 2vw;
  align-items: center;
  /* flex-grow: 1; */
  margin: auto 10vw;
}
.nav-item {
  color: rgb(33, 34, 35);
  font-size: 28px;
  text-decoration: none;
}
.search-container {
  /* padding: auto 5px; */
  padding-left: 10px;
  display: flex;
  align-items: center;
  /* gap: .5rem; */
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 50px;
}

.icon-container {
  /* color: whitesmoke; */
  height: 20px;
  width: 20px;
  padding: 2px;
  margin-left: 0;
}
.input-box {
  width: 15vw;
  height: 30px;
  border: 1px solid rgb(207, 219, 225);
}
.filter {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
  padding-top: 50px;
  justify-content: space-evenly;
}
